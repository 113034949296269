import React from 'react';

export function Alert({ className, children }) {
  return (
    <div className={`alert ${className}`}>
      {children}
    </div>
  );
}

export function AlertDescription({ children }) {
  return (
    <p className="alert-description">
      {children}
    </p>
  );
}
