import React from 'react';

export const Input = ({ type = "text", value, onChange, className }) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={`input ${className}`}
    />
  );
};
