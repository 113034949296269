import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchActivityInfo } from "../../API/activity/Activity";
import { sendFormData } from "../../API/activity/Activity";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

// Utilisation de styled-components pour le dialog personnalisé
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function Donation() {
    const { t, i18n } = useTranslation();
    const [donationModalOpen, setDonationModalOpen] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [formState, setFormState] = useState(JSON.parse(localStorage.getItem("formState")) || {
      emailL: "",
      nomL: "",
      phoneL: "",
      prenomL: "",
    });
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(""); // Nouveau state pour le message de réponse
    const [responseMessageType, setResponseMessageType] = useState(""); // Nouveau state pour le type du message
  
    useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      const browserLang = savedLanguage || navigator.language || navigator.userLanguage;
      const lang = browserLang.substr(0, 2);
      i18n.changeLanguage(lang);
  
      fetchActivityInfo()
        .then(response => {
          setCurrency(response.data.info.currency);
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des infos d'activité:", error);
        });
  
      return () => {
        i18n.off("languageChanged");
      };
    }, [i18n]);
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormState(prevState => {
        const updatedForm = { ...prevState, [name]: value };
        localStorage.setItem("formState", JSON.stringify(updatedForm));
        return updatedForm;
      });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      console.log("Form submitted:", formState); // Debug
  
      try {
        const response = await sendFormData(formState);
        console.log("Response:", response); // Debug
  
        // Mettre à jour le message de succès
        setResponseMessage(t("form.success"));
        setResponseMessageType("success");
        setFormState({
          emailL: "",
          nomL: "",
          phoneL: "",
          prenomL: "",
        });
      } catch (error) {
        const errorMessage = error.response?.data?.message || t("form.error");
        console.log("Error message:", errorMessage); // Debug
  
        // Mettre à jour le message d'erreur
        setResponseMessage(errorMessage);
        setResponseMessageType("error");
      } finally {
        setLoading(false);
      }
    };
  
    const handleOpen = () => setDonationModalOpen(!donationModalOpen);
  
    return (
      <>
        <ToastContainer />
        <Button
          onClick={handleOpen}
          variant="text"
          className="bg-[#6F0C11] hover:bg-[#121212] rounded-none text-white sm:w-[800px] h-14 text-base flex flex-row items-end justify-center space-x-6"
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.3334 17.5C27.3584 17.5 30.6251 14.2333 30.6251 10.2083C30.6251 6.18329 27.3584 2.91663 23.3334 2.91663C19.3084 2.91663 16.0417 6.18329 16.0417 10.2083C16.0417 14.2333 19.3084 17.5 23.3334 17.5ZM31.2813 25.6666C30.7126 25.0833 29.998 24.7916 29.1667 24.7916H18.9584L15.9251 23.727L16.4063 22.3562L18.9584 23.3333H23.0417C23.5521 23.3333 23.9605 23.1291 24.2959 22.7937C24.6313 22.4583 24.7917 22.05 24.7917 21.5979C24.7917 20.8104 24.4126 20.2708 23.6542 19.9645L13.0521 16.0416H10.2084V29.1666L20.4167 32.0833L32.1271 27.7083C32.1417 26.9354 31.8501 26.25 31.2813 25.6666ZM7.29172 16.0416H1.43506V32.0833H7.29172V16.0416Z"
              fill="white"
            />
          </svg>
          <p>{t("Envoyer un don")}</p>
        </Button>
        <Dialog open={donationModalOpen} handler={handleOpen} size="xs">
          <DialogHeader className="justify-center">
            <div className="flex items-center space-x-2 justify-center">
              <svg
                width="22"
                height="22"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.3334 17.5C27.3584 17.5 30.6251 14.2334 30.6251 10.2084C30.6251 6.18335 27.3584 2.91669 23.3334 2.91669C19.3084 2.91669 16.0417 6.18335 16.0417 10.2084C16.0417 14.2334 19.3084 17.5 23.3334 17.5ZM31.2813 25.6667C30.7126 25.0834 29.998 24.7917 29.1667 24.7917H18.9584L15.9251 23.7271L16.4063 22.3563L18.9584 23.3334H23.0417C23.5521 23.3334 23.9605 23.1292 24.2959 22.7938C24.6313 22.4584 24.7917 22.05 24.7917 21.5979C24.7917 20.8104 24.4126 20.2709 23.6542 19.9646L13.0521 16.0417H10.2084V29.1667L20.4167 32.0834L32.1271 27.7084C32.1417 26.9354 31.8501 26.25 31.2813 25.6667ZM7.29172 16.0417H1.43506V32.0834H7.29172V16.0417Z"
                  fill="#ef061a"
                />
              </svg>
              <p className="uppercase text-[#6F0C11] text-lg font-medium">{t("Faire un don")}</p>
            </div>
          </DialogHeader>
          <DialogBody className="flex justify-center items-center h-[28rem] overflow-y-scroll pl-8 overflow-x-hidden">
            <form className="mt-6 flex flex-col space-y-3 w-[300px]" onSubmit={handleSubmit}>
              {/* Affichage du message de réponse en haut du formulaire */}
              {responseMessage && (
                <div className={`text-center mb-6 p-2 text-xl font-medium ${responseMessageType === "success" ? "text-green-600" : "text-[#6F0C11]"}`}>
                  {responseMessage}
                </div>
              )}
              <label>{t("Email")}</label>
              <input
                name="emailL"
                type="email"
                value={formState.emailL}
                onChange={handleInputChange}
                className="w-[250px] outline-none bg-[#f8f8f8] h-12 px-2"
              />
              <label>{t("Nom")}</label>
              <input
                name="nomL"
                type="text"
                value={formState.nomL}
                onChange={handleInputChange}
                className="w-[250px] outline-none bg-[#f8f8f8] h-12 px-2"
              />
              <label>{t("Téléphone")}</label>
              <input
                name="phoneL"
                type="text"
                value={formState.phoneL}
                onChange={handleInputChange}
                className="w-[250px] outline-none bg-[#f8f8f8] h-12 px-2"
              />
              <label>{t("Prénom")}</label>
              <input
                name="prenomL"
                type="text"
                value={formState.prenomL}
                onChange={handleInputChange}
                className="w-[250px] outline-none bg-[#f8f8f8] h-12 px-2"
              />
              <button
                type="submit"
                className="bg-[#6F0C11] text-white h-14 text-sm mt-3 hover:bg-[#121212] rounded-none"
                disabled={loading}
              >
                {loading ? t("Chargement...") : t("Envoyer le don")}
              </button>
            </form>
          </DialogBody>
          <DialogFooter className="justify-center">
            <Button
              color="red"
              onClick={handleOpen}
              variant="outlined"
              className="text-[#6F0C11]"
            >
              {t("Fermer")}
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }