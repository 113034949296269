import axios from 'axios';

// Fetch media from the server via the API route
export const fetchMedia = async () => {
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/galeries`);
    return response.data;
  } catch (error) {
    console.error("Error fetching media:", error);
    throw error;
  }
};
