import React from 'react';
export const Textarea = ({ value, onChange, className }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      className={`textarea ${className}`}
    />
  );
};
