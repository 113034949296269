import React, { useState, useEffect } from 'react';
import Header from "../../COMPONENTS/Header/Header";
import { NavbarDefault } from "../../COMPONENTS/Navbar/Navbar";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Footer from "../../COMPONENTS/Footer/Footer";
import { fetchComites } from '../../API/comite/comite';
import { fetchNouvelleInfo } from '../../API/nouvelle/Nouvelle';
import Loader from "../../COMPONENTS/Loader/Loading";

const Comite = () => {
  const [NameSite, setNameSite] = useState('');
  const [openIndex, setOpenIndex] = useState(null);
  const [info, setInfo] = useState({});
  const [comites, setComites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setLoading(false), 10000); // Limiter à 10 secondes

    fetchNouvelleInfo()
      .then(response => {
        setNameSite(response.data.info.name);
        setInfo(response.data.info);
      })
      .catch(error => {
        console.error('Il y avait une erreur!', error);
      })
      .finally(() => setLoading(false)); 

    return () => clearTimeout(timeoutId); // Nettoie le timeout si le composant est démonté
  }, []);

  useEffect(() => {
    const getComites = async () => {
      try {
        const response = await fetchComites();
        if (response.data.success) {
          setComites(response.data.data);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des comités:", error);
      }
    };
    getComites();
  }, []);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (loading) {
    return <Loader />; // Utilise le composant Loader personnalisé
  }

  return (
    <>
      <div>
        <Header info={info} />
        <NavbarDefault info={info} />
        
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <h1 className="text-3xl font-bold mb-8 text-center">Les Comités</h1>
          <div className="space-y-4">
            {comites.map((comite, index) => (
              <div 
                key={comite.id} 
                className="border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <button
                  className="w-full px-6 py-4 text-left bg-white hover:bg-gray-50 flex justify-between items-center"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="text-lg font-medium text-[#6F0C11]">{comite.title}</span>
                  {openIndex === index ? 
                    <FaChevronUp className="text-[#6F0C11]" /> : 
                    <FaChevronDown className="text-[#6F0C11]" />
                  }
                </button>
                
                {openIndex === index && (
                  <div className="px-6 py-4 bg-white">
                    <div className="mb-4">
                      <h3 className="font-semibold mb-2">Description:</h3>
                      <p className="text-gray-700">{comite.description}</p>
                    </div>
                    <div className="mb-4">
                      <h3 className="font-semibold mb-2">Membres:</h3>
                      <ol className="list-decimal list-inside text-gray-700">
                        {comite.list_membres 
                          ? comite.list_membres.split(',').map((membre, idx) => (
                              <li key={idx}>{membre.trim()}</li>
                            ))
                          : <li>Aucun membre</li>
                        }
                      </ol>
                    </div>
                    <div>
                      <h3 className="font-semibold mb-2">Mandat:</h3>
                      <p className="text-gray-700">
                        Du {comite.date_debut} au {comite.date_fin}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <div className="pt-20">
          <Footer info={info} />
        </div>
      </div>
    </>
  );
};

export default Comite;
