import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from '../../COMPONENTS/ui/card';
import { Button } from '../../COMPONENTS/ui/button';
import { Input } from '../../COMPONENTS/ui/input';
import { Textarea } from '../../COMPONENTS/ui/textarea';
import { Alert, AlertDescription } from '../../COMPONENTS/ui/alert';
import { Calendar, Users } from 'lucide-react';
import Modal from '../../COMPONENTS/ui/modal'; // Import your Modal component here
import axios from 'axios'; // Ensure you have axios installed

const UnivCercle = () => {
  const [showModal, setShowModal] = useState(false);
  const [reservationDetails, setReservationDetails] = useState({
    name: '',
    lastName: '',
    email: '',
    date: '',
    purpose: '',
    phone: '', // Add phone to the state
  });
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message

  const handleReservationSubmit = async (e) => {
    e.preventDefault();
  
    if (!reservationDetails.name || !reservationDetails.lastName || !reservationDetails.email || !reservationDetails.date || !reservationDetails.purpose || !reservationDetails.phone) {
        setErrorMessage('Veuillez remplir tous les champs.');
        return;
    }
  
    setErrorMessage('');
  
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customers`, {
            Names: reservationDetails.name,
            LastName: reservationDetails.lastName,
            'E-mails': reservationDetails.email,
            Phones: reservationDetails.phone,
            starting_date: reservationDetails.date,
            notes: reservationDetails.purpose,
        });

        setSuccessMessage("Réservation soumise avec succès !");
        setShowModal(false);
        setReservationDetails({
            name: '',
            lastName: '',
            email: '',
            date: '',
            purpose: '',
            phone: '',
        });
    } catch (error) {
        console.error("Une erreur est survenue lors de la soumission de la réservation !", error);
        setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
    }
};

  

  return (
    <div className="max-w-4xl mx-auto p-4 min-h-screen">
      <h1 className="text-lg md:text-4xl font-semibold mb-8 text-center text-[#4E4E4E]">Cercle Universitaire</h1>

      <Card className="mb-8 shadow-lg hover:shadow-xl transition-shadow duration-300 p-5">
        <CardHeader className="bg-[#6F0C11] text-white w-full">
          <h2 className="text-2xl font-semibold flex items-center justify-center w-full">
            <Users className="mr-2" /> Politique du Cercle Universitaire
          </h2>
        </CardHeader>
        <CardContent className="mt-4">
          <p className="text-gray-700">Le Cercle Universitaire est ouvert à tous les membres de la communauté universitaire. Voici nos principales règles :</p>
          <ul className="list-none pl-5 mt-4 space-y-2">
            {[
              "Respectez les lieux et les autres utilisateurs",
              "Les réservations doivent être faites au moins 48h à l'avance",
              "Le paiement est requis pour confirmer la réservation",
              "L'annulation est possible jusqu'à 24h avant l'événement"
            ].map((rule, index) => (
              <li key={index} className="flex items-start">
                <span className="text-black-500 mr-2">•</span>
                <span>{rule}</span>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <div className="text-center">
        <Button 
          onClick={() => setShowModal(true)} 
          className="bg-[#6F0C11] hover:bg-[#121212] text-white font-bold py-2 px-4 rounded-full transition-colors duration-300"
        >
          Faire une demande de réservation
        </Button>
        
        {successMessage && (
          <div className="mt-4 text-green-600 font-semibold">
            {successMessage}
          </div>
        )}
      </div>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
  <Card className="shadow-lg max-h-[80vh] overflow-auto"> {/* Limite la hauteur et permet le défilement */}
    <CardHeader className="bg-[#6F0C11] text-white">
      <h2 className="text-2xl font-semibold flex items-center">
        <Calendar className="mr-2" /> Formulaire de Réservation
      </h2>
    </CardHeader>
    <CardContent className="mt-4">
      {errorMessage && (
        // <Alert className="mb-4 bg-red-100 border-red-400 text-red-700 p-4 rounded">
          <Alert className="mb-4 p-4 rounded" style={{ backgroundColor: '#F3D1D3', borderColor: '#6F0C11', color: '#6F0C11' }}>

          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      <form onSubmit={handleReservationSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Nom</label>
          <Input
            type="text"
            id="name"
            value={reservationDetails.name}
            onChange={(e) => setReservationDetails({...reservationDetails, name: e.target.value})}
            required
            className="w-full p-2 border rounded focus:ring-2"
            style={{ borderColor: '#6F0C11', outlineColor: '#6F0C11' }}            
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Prénom</label>
          <Input
            type="text"
            id="lastName"
            value={reservationDetails.lastName}
            onChange={(e) => setReservationDetails({...reservationDetails, lastName: e.target.value})}
            required
            className="w-full p-2 border rounded focus:ring-2"
            style={{ borderColor: '#6F0C11', outlineColor: '#6F0C11' }}          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
          <Input
            type="email"
            id="email"
            value={reservationDetails.email}
            onChange={(e) => setReservationDetails({...reservationDetails, email: e.target.value})}
            required
            className="w-full p-2 border rounded focus:ring-2"
            style={{ borderColor: '#6F0C11', outlineColor: '#6F0C11' }}          />
        </div>
        <div>
    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Numéro de téléphone</label>
    <Input
        type="tel"
        id="phone"
        value={reservationDetails.phone}
        onChange={(e) => setReservationDetails({...reservationDetails, phone: e.target.value})}
        required
        className="w-full p-2 border rounded focus:ring-2 focus:ring-red-500"
    />
</div>


        <div>
          <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Date</label>
          <Input
            type="date"
            id="date"
            value={reservationDetails.date}
            onChange={(e) => setReservationDetails({...reservationDetails, date: e.target.value})}
            required
            className="w-full p-2 border rounded focus:ring-2"
            style={{ borderColor: '#6F0C11', outlineColor: '#6F0C11' }}          />
        </div>
        <div>
          <label htmlFor="purpose" className="block text-sm font-medium text-gray-700 mb-1">Objet de la réservation</label>
          <Textarea
            id="purpose"
            value={reservationDetails.purpose}
            onChange={(e) => setReservationDetails({...reservationDetails, purpose: e.target.value})}
            required
            className="w-full p-2 border rounded focus:ring-2"
            style={{ borderColor: '#6F0C11', outlineColor: '#6F0C11' }}
          />
        </div>
        <Button type="submit" className="w-full bg-[#6F0C11] hover:bg-[#121212] text-white font-bold py-2 px-4 rounded transition-colors duration-300">
          Soumettre la demande
        </Button>
      </form>
    </CardContent>
  </Card>
</Modal>

    </div>
  );
};

export default UnivCercle;
